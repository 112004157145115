* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}

body {
  background-color: #4caf50;
}

.home{
  background-color: #4caf50;
  color: azure;
  align-items: center;
  justify-content: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
}

.body {
  padding-top: 1%;
  padding-bottom: 1%;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  padding-left: 20%;
  padding-right: 20%;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .body{
    padding-left: 5%;
    padding-right: 5%;
  }
}



.icon{
  padding-top: 2%;
  padding-bottom: 1%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 7rem;
}

.platformLinks{
  padding-top: 1%;
  display: flex;
  justify-content: center;
}

.storeBadgeApple{
  width: 47%;
  padding-right: 2%;
}
.storeBadgeAndroid{
  width: 50%;
  padding-left: 2%;
}

a.appleLink {
  display: flex;
  align-items: center;
}

.appleLink {
  justify-content: flex-end;
}

.androidLink {
  justify-content: flex-start;
}

.content{
  background-color: #4caf50;
  color: azure;
  align-content: center;
  padding-left: 20%;
  padding-top: 3vw;
  padding-right: 20%;
}

@media screen and (max-width: 960px) {
  .content{
    padding: 5%;
  }
  .storeBadgeApple{
    width: 40%;
  }
  .storeBadgeAndroid{
    width: 40%;
  }
}

.content p{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.content ol{
  padding-left: 2rem;
}


h1 {
  font-family: 'Comfortaa', cursive;
  text-align: center;
}

h2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

h3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

h4 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}
